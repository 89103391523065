
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import ECharts from 'vue-echarts';
import ShiftFilter from '../ShiftFilter.vue';
import ThreeDotsOptions from '@/components/Charts/ThreeDotsOptions.vue';
import { UserTarget } from '@/models/userTarget';
import { userTargetsService } from '@/services/userTargets.service';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import TimeSpanFilter from '../TimeSpanFilter.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { numberToArray } from '@/models/metrics/aggregates';
import { Routes } from '@/router/routes';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import i18n from '@/i18n';
import { addAutoRemovedEventListener, isEmpty, isNil } from '@/utils/misc';
import { devicesService } from '@/services/devices.service';
import { shiftsService } from '@/services/shifts.service';
import { calculateMaxCuttingTime } from '@/components/Charts/maxCuttingTime';
import NewFeatureBadge from '../NewFeatureBadge.vue';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import { GeneratorParams } from '@/models/Charts/generatorParams';
import WidgetFactory from '@/models/Charts/widgetFactory';
import { listenersWithPrefix } from '@/utils/components';

@Component({
  methods: { isEmpty },
  components: {
    ShiftFilter,
    ThreeDotsOptions,
    TimeSpanFilter,
    NewFeatureBadge,
  },
})
export default class Chart extends Vue {
  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true })
  private generator!: ChartGenerator<any>;

  @Prop({ required: true })
  private data!: any;

  @Prop({ required: true })
  private paramValues!: Record<string, any>;

  @Prop({ default: false })
  private selfManagedFilters!: boolean;

  @Prop({ required: false })
  private customerNo?: string;

  private generatorParams: GeneratorParams = {};
  private options: any = null;

  private longPressStart = 0;
  private longPressEnd = 0;

  @Ref('chart')
  private chart?: typeof ECharts;

  private get echartsListeners() {
    return listenersWithPrefix('echarts', this);
  }

  private async mounted() {
    if (this.selfManagedFilters) {
      // When switching back and forth from and to fullscreen mode (F11), widgets
      // might overflow the card vertically. We don't know the cause but using
      // this resize listener fixes it.
      addAutoRemovedEventListener(this, 'resize', this.onResize);
    }

    this.updateOptions();
  }

  @Watch('widgetDefinition')
  private watchWidgetDefinition(newValue: WidgetDefinition, oldValue: WidgetDefinition) {
    if (newValue.widget !== oldValue.widget) {
      this.chart?.clear();
    }
    this.updateOptions();
  }

  private updateOptions() {
    if (isEmpty(this.data)) {
      return;
    }

    const generatorParams = WidgetFactory.createOptions(this.widgetDefinition.widget);

    this.generatorParams = {
      ...generatorParams,
      clientWidth: this.$el?.clientWidth,
      clientHeight: this.$el?.clientHeight,
      targets: this.getTargets(),
      aggregates: this.aggregates,
      paramValues: this.paramValues,
      customerNo: this.customerNo,
      timeAxisSpan: this.widgetDefinition.axisSpan,
    };

    if (
      this.widgetDefinition.widget === WidgetEnum.LaserCuttingTime ||
      this.widgetDefinition.widget === WidgetEnum.TubeCuttingTime
    ) {
      this.generatorParams.maxValue = this.calculateMaxCuttingTime();
    }

    // Some constructors inadvertently mutate the original data (using .sort() in arrays, for example).
    // If data is mutated from within the generator, this function is recursively called by the watcher.
    // If a clone of the data is passed, we don't need to worry about mutating data, and the issue is prevented.
    const dataClone = Array.isArray(this.data) ? this.data.concat() : { ...this.data };
    this.options = {
      textStyle: { fontFamily: 'Inter' },
      ...this.generator?.updateOptions(dataClone, this.generatorParams, this.chart?.getOption()),
    };
  }

  @Watch('locale')
  @Watch('data')
  private watchChanges() {
    this.updateOptions();
  }

  private get aggregates(): string[] {
    return numberToArray(this.widgetDefinition.aggregates);
  }

  private get echartsUpdateOptions(): any {
    return { notMerge: true };
  }

  private get locale(): string {
    return i18n.locale;
  }

  private getTargets() {
    return this.selfManagedFilters ? this.widgetDefinition.target : this.getDefaultTarget();
  }

  private onClickEvent(params: any) {
    if (this.widgetDefinition.widget === WidgetEnum.FactoryOverview) {
      let route;
      if (params.name.startsWith('L-')) {
        route = Routes.Cut;
      } else if (params.name.startsWith('B-')) {
        route = Routes.Bend;
      } else if (params.name.startsWith('T-')) {
        route = Routes.Tubes;
      } else {
        return;
      }

      this.$router.push({
        name: route,
        params: {
          selectedDevicesInput: JSON.stringify([params.name]),
        },
      });
    }
  }

  private onLegendSelectChanged(params: any) {
    if (this.widgetDefinition.widget === WidgetEnum.TechnologyDrawerInformationTimeline) {
      this.options = {
        textStyle: { fontFamily: 'Inter' },
        ...this.generator?.updateOptions(this.data, {
          ...this.generatorParams,
          removedSecondsSelected: params.selected.removed_seconds,
          pdSelected: params.selected.pd,
          powerAmplitudeSelected: params.selected.power_amplitude,
        }),
      };
    } else if (
      this.widgetDefinition.widget === WidgetEnum.LaserOutputAndScrapTimeline ||
      this.widgetDefinition.widget === WidgetEnum.TubeOutputScrapHist
    ) {
      this.options = {
        textStyle: { fontFamily: 'Inter' },
        ...this.generator?.updateOptions(this.data, {
          ...this.generatorParams,
          legendParams: params,
        }),
      };
    }
  }

  private canExpandOnDblClick(): boolean {
    return this.widgetDefinition.canExpandOnDblClick();
  }

  private get targetsMetric(): TargetsMetric | null {
    return !!this.widgetDefinition
      ? this.getWidgetTargetMetric(this.widgetDefinition.widget)
      : null;
  }

  private getWidgetTargetMetric(widget: WidgetEnum): TargetsMetric {
    let widgetString = Object.keys(WidgetEnum).find(
      (key: string) => WidgetEnum[key as keyof typeof WidgetEnum] === widget,
    )!;

    // HACK (temporary): We don't want a target for LaserAvailability pie chart,
    // but it will be added in the future.
    if (widgetString !== 'LaserProductivityHist') {
      widgetString = widgetString.replace('Hist', '');
    }

    return widgetString in TargetsMetric
      ? TargetsMetric[widgetString as keyof typeof TargetsMetric]
      : TargetsMetric.None;
  }

  private onResize() {
    this.chart?.resize();
  }

  private getDefaultTarget() {
    if (this.widgetDefinition.widget === WidgetEnum.SalesTimeline) {
      return this.getSalesTimelineTargets();
    } else {
      const nTargets = userTargetsService.store.getMetricNumberOfTargetValues(this.targetsMetric);
      if (nTargets === 0) {
        return;
      }
      const defaultTarget: number[] = [];
      const userTarget = this.getUserTarget(this.targetsMetric);

      if (userTarget) {
        defaultTarget.push(userTarget.target1);
        if (nTargets === 2 && !!userTarget.target2) {
          defaultTarget.push(userTarget.target2);
        }
      }

      return userTarget ? defaultTarget : undefined;
    }
  }

  private getSalesTimelineTargets(): number[] | undefined {
    const monthlyPaid = this.getUserTarget(TargetsMetric.SalesFunnelMonthlyPaid)?.target1;
    const r3 = this.getUserTarget(TargetsMetric.SalesFunnelR3)?.target1;
    const r4 = this.getUserTarget(TargetsMetric.SalesFunnelR4)?.target1;

    if (monthlyPaid && r3 && r4) {
      return [monthlyPaid, r3, r4];
    } else {
      return undefined;
    }
  }

  private getUserTarget(targetMetric: TargetsMetric | null): UserTarget | undefined {
    return userTargetsService.store.get(targetMetric);
  }

  private onExpandOnDblClick(params: any) {
    if (this.canExpandOnDblClick()) {
      this.$emit('double-click-expand', params);
    }
  }

  private calculateMaxCuttingTime() {
    const deviceIds = devicesService.store.deviceIdsToIds(
      this.widgetDefinition.deviceId as string[],
    );
    const selectedShifts = shiftsService.store.getMatchingIds(this.widgetDefinition.shifts);

    return calculateMaxCuttingTime(deviceIds, selectedShifts, this.widgetDefinition.timeFilter);
  }

  private mouseUp(params: any) {
    if (this.isMobileOrTablet() && this.canExpandOnDblClick()) {
      this.longPressEnd = Date.now();
      if (this.longPressEnd - this.longPressStart > 500) {
        this.onExpandOnDblClick(params);
      }
    }
  }

  private mouseDown(params: any) {
    if (this.isMobileOrTablet() && this.canExpandOnDblClick()) {
      this.longPressStart = Date.now();
    }
  }

  private isMobileOrTablet() {
    const ua = navigator.userAgent;
    return (
      /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      ) ||
      // iPad on iOS 13 and up detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  private get showChart(): boolean {
    return !isEmpty(this.data) && !isNil(this.options);
  }
}
